"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: '斉藤麻里 / SaitoMari',
    organizationId: '8E4lL4s5D6wmp1QJhsew',
    version: '1.0.0',
    ionicAppId: '',
    applicationKey: 'maritree',
    applicationType: 'standalone',
    artistId: 'Zq4O19dGixSzWdcY3qigDFQvlJ92',
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.maritree',
        appId: '',
    },
    android: {
        bundleId: 'com.utoniq.maritree',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: true,
            thread: false,
            prepaidPoint: null,
            gacha: false,
            nft: false,
            liveStream: true,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: true,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'collections', 'posts', 'notifications', 'myProfile'],
    },
    deeplinkUrl: 'https://maritree.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3564140',
    storagePath: 'maritree',
    dynamicLinkPrefix: 'maritree',
};
exports.default = appConfig;
